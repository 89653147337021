import {uniqBy} from 'lodash';
import {forwardRef, MouseEventHandler, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {escapeRegExp} from '../../../components/InputDate/InputDate.helpers';

/* eslint react/require-default-props: 0 */
export type HighlightProps = {
  text: string;
  phrase: string | string[];
  highlightClassName?: string;
  className?: string;
  caseSensitive?: boolean;
  firstMatchOnly?: boolean;
  onClick?: () => void;
  ellipsis?: boolean | 'rightSideOnly';
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
  testId?: string;
  title?: string;
};

type MatchType = {0: string; index: number};

/* eslint react/require-default-props: 1 */
export const getSlices = (
  text: string,
  phrases: string[],
  caseSensitive: boolean,
  firstMatchOnly: boolean,
): [string, boolean][] => {
  let matches = Array.from(
    (caseSensitive ? text : text.toLowerCase()).matchAll(
      phrases.map(s => escapeRegExp(caseSensitive ? s : s.toLowerCase())).join('|') as unknown as RegExp,
    ),
  ) as MatchType[];

  if (firstMatchOnly) {
    matches = uniqBy(matches, '0');
  }

  const output: [string, boolean][] = [];

  if (matches.length === 0) {
    return [[text, false]];
  }

  output.push([text.slice(0, matches[0].index), false]);

  for (let i = 0; i < matches.length; i += 1) {
    output.push([text.slice(matches[i].index, matches[i].index + matches[i][0].length), true]);
    if (i < matches.length - 1) {
      output.push([text.slice(matches[i].index + matches[i][0].length, matches[i + 1].index), false]);
    } else {
      output.push([text.slice(matches[i].index + matches[i][0].length), false]);
    }
  }

  return output.filter(([s]) => s.length > 0);
};

export const Highlight = forwardRef<HTMLSpanElement, HighlightProps>(
  (
    {
      caseSensitive = false,
      className = '',
      text,
      phrase,
      highlightClassName = 'bg-primary-100',
      firstMatchOnly = true,
      onClick = undefined,
      ellipsis = false,
      onMouseEnter = undefined,
      onMouseLeave = undefined,
      testId = 'highlight',
      title = undefined,
    },
    ref,
  ) => {
    const slices = useMemo<[[string, boolean], string][]>(
      () =>
        getSlices(text || '', typeof phrase === 'string' ? [phrase] : phrase, caseSensitive, firstMatchOnly).map(
          slice => [slice, crypto.randomUUID()],
        ),
      [text, phrase, caseSensitive, firstMatchOnly],
    );

    const ellipsisClassNameForIndex = (index: number): string => {
      const outputClassArr: string[] = [];

      if (slices[index][0][0].startsWith(' ')) {
        outputClassArr.push('space-before');
      }
      if (slices[index][0][0].endsWith(' ')) {
        outputClassArr.push('space-after');
      }

      if (ellipsis) {
        const isTextWithoutHighlight = slices.length === 1;
        const isPreEllipsis = slices.length > 1 && index === 0 && ellipsis !== 'rightSideOnly';
        const isPostEllipsis = slices.length > 1 && index === slices.length - 1;
        const isEllipsis = isTextWithoutHighlight || isPreEllipsis || isPostEllipsis;

        if (isEllipsis) {
          outputClassArr.push(...['whitespace-nowrap', 'overflow-hidden', 'text-ellipsis']);

          if (className.includes('flex')) {
            outputClassArr.push('grow');
            outputClassArr.push('shrink');
          }
        }
      }

      return outputClassArr.join(' ');
    };
    const dirForIndex = (index: number): 'rtl' | 'ltr' | undefined => {
      if (slices.length === 1 || !ellipsis) {
        return undefined;
      }
      if (index === 0) {
        if (ellipsis === 'rightSideOnly') {
          return undefined;
        }
        return 'rtl';
      }
      return 'ltr';
    };

    return (
      <span
        title={title}
        className={twMerge(clsx('whitespace-pre min-w-0 max-w-full', {flex: ellipsis}, className))}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        data-testid={testId}
      >
        {slices.map(([[sliceText, isHighlighted], key], index) => (
          <span
            dir={dirForIndex(index)}
            key={key}
            className={twMerge(
              clsx(ellipsisClassNameForIndex(index), {
                'grow-0 shrink-0': isHighlighted && className.includes('flex'),
                [highlightClassName]: isHighlighted,
              }),
            )}
          >
            <span className="unicodeBidiOverride directionLtr">
              {sliceText.replace(/^[^\S\r\n]+|[^\S\r\n]+$/g, '')}
            </span>
          </span>
        ))}
      </span>
    );
  },
);
