import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {debounce} from 'lodash';
import {Portal, PortalProps} from '../../external/components/Portal/Portal';
import {Level, LevelContext} from '../../external/context/LevelContext';
import {isMouseOver} from '../../external/components/Portal/Portal.helpers';
import {ReactComponent as ArrowIcon} from '../Icon/assets/icon-arrow-drop-down-contour.svg';

const arrow: (className: string) => FC<{placement: 'top' | 'center' | 'bottom'; side: 'left' | 'right' | 'center'}> =
  className =>
  ({placement, side}: {placement: 'top' | 'center' | 'bottom'; side: 'left' | 'right' | 'center'}) => {
    if (placement === 'bottom') {
      return (
        <ArrowIcon className={twMerge(clsx('absolute z-[1] -top-[30px] -left-[21px] w-[42px] h-[42px]', className))} />
      );
    }

    if (placement === 'top') {
      return (
        <ArrowIcon
          className={twMerge(
            clsx('rotate-[180deg] absolute z-[1] -left-[21px] -top-[12px] w-[42px] h-[42px]', className),
          )}
        />
      );
    }

    if (side === 'right') {
      return (
        <ArrowIcon
          className={twMerge(
            clsx('-rotate-[90deg] absolute z-[1] -left-[30px] -top-[21px] w-[42px] h-[42px]', className),
          )}
        />
      );
    }

    if (side === 'left') {
      return (
        <ArrowIcon
          className={twMerge(
            clsx('rotate-[90deg] absolute z-[1] -left-[12px] -top-[21px] w-[42px] h-[42px]', className),
          )}
        />
      );
    }

    return <></>;
  };

export type TooltipProps = {
  text?: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  /** Usually tooltip works on :hover, however certain state can be forced
   * by setting this flag */
  open?: boolean;
  tooltipClass?: string;
  portalClass?: string;
  position?: TooltipPosition;
  arrowClass?: string;
  testId?: string;
  delay?: number;
  transition?: boolean;
  hideWhenClicked?: boolean;
};

export enum TooltipPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

const positionToOrigin = (
  position: TooltipPosition | undefined,
): Pick<PortalProps, 'anchorOrigin' | 'transformOrigin'> => {
  if (position === TooltipPosition.TOP) {
    return {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    };
  }
  if (position === TooltipPosition.RIGHT) {
    return {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'center',
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'center',
      },
    };
  }
  if (position === TooltipPosition.BOTTOM) {
    return {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    };
  }
  if (position === TooltipPosition.LEFT) {
    return {
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'center',
      },
      transformOrigin: {
        horizontal: 'right',
        vertical: 'center',
      },
    };
  }
  return {
    anchorOrigin: {
      horizontal: 'center',
    },
    transformOrigin: {
      horizontal: 'center',
    },
  };
};

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  text = '',
  position = undefined,
  onOpen = () => undefined,
  onClose = () => undefined,
  open = undefined,
  children = undefined,
  tooltipClass = 'max-w-[330px]',
  portalClass = undefined,
  arrowClass = 'filter-grey-900',
  testId = 'tooltip',
  delay = 0,
  transition = true,
  hideWhenClicked = true,
}) => {
  const isReactElement = isValidElement(text);
  const level = useContext(LevelContext);
  const portalRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLElement>();
  const child = Children.only(children) as ReactElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const childRef = child.ref as RefObject<HTMLElement>;
  const hasRef = childRef !== null;
  const output = useMemo(() => (hasRef ? child : cloneElement(child, {ref})), [children]);
  const [isOpen, _setIsOpen] = useState(open || false);
  const setIsOpen = (b: boolean) => {
    _setIsOpen(b);
    if (b && onOpen) {
      onOpen();
    }
    if (!b && onClose) {
      onClose();
    }
  };

  const hideTooltipRef = useRef<(mouseEvent: MouseEvent) => void>();

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  const delayTime = delay || parseInt(document.body.getAttribute('data-tooltip-delay') || '0', 10);
  const showTooltip = debounce(() => {
    setIsOpen(true);
  }, delayTime);

  useEffect(() => {
    const anchorEl = (childRef || ref).current as HTMLElement;
    hideTooltipRef.current = (mouseEvent: MouseEvent) => {
      showTooltip.cancel();

      if (!isReactElement) {
        setIsOpen(false);
      } else {
        let latestMouseEvent: MouseEvent = mouseEvent;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const mouseMoveCapture = (mouseEvent: MouseEvent) => {
          latestMouseEvent = mouseEvent;
        };

        document.addEventListener('mousemove', mouseMoveCapture, true);
        setTimeout(() => {
          document.removeEventListener('mousemove', mouseMoveCapture, true);
          const portalEl = portalRef.current?.parentElement as HTMLElement;
          const elements = [anchorEl, portalEl];

          if (!isMouseOver(elements, latestMouseEvent, 0, true)) {
            setIsOpen(false);
          }
        }, 120);
      }
    };

    const hideTooltipEagerly = () => setIsOpen(false);

    if (anchorEl && open === undefined) {
      anchorEl.addEventListener('mouseenter', showTooltip, true);
      anchorEl.addEventListener('mouseleave', hideTooltipRef.current as (mouseEvent: MouseEvent) => void, true);
      if (hideWhenClicked) {
        anchorEl.addEventListener('mousedown', hideTooltipEagerly, true);
      }
    }
    return () => {
      if (anchorEl && open === undefined) {
        anchorEl.removeEventListener('mouseenter', showTooltip, true);
        anchorEl.removeEventListener('mouseleave', hideTooltipRef.current as (mouseEvent: MouseEvent) => void, true);
        if (hideWhenClicked) {
          anchorEl.removeEventListener('mousedown', hideTooltipEagerly, true);
        }
      }
    };
  }, []);

  const renderContent = () => (
    <div
      onMouseDownCapture={event => {
        if (!hideWhenClicked) {
          event.stopPropagation();
        }
      }}
      onClick={event => {
        if (!hideWhenClicked) {
          event.stopPropagation();
        }
      }}
      data-testid={testId}
      className={twMerge(
        clsx(
          'top-[16px] flex max-w-[90vw] items-center m-[12px] w-max px-[16px] py-[8px] bg-black text-white rounded-[4px]',
          tooltipClass || '',
        ),
      )}
    >
      {text}
    </div>
  );

  return (
    <>
      {output}
      {!!text && (
        <Portal
          ref={portalRef}
          duration={transition ? 250 : undefined}
          transitionName={transition ? 'tooltip' : undefined}
          Junction={arrow(arrowClass)}
          backdrop={false}
          isOpen={isOpen}
          portalClassName={portalClass}
          className={clsx({'pointer-events-none': !isReactElement})}
          anchorEl={childRef || ref}
          onMouseLeave={hideTooltipRef.current}
          zIndex={level + Level.Message}
          isTooltip
          {...positionToOrigin(position)}
        >
          {renderContent()}
        </Portal>
      )}
    </>
  );
};
